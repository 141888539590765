var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-tab-overview"},[_c('v-card',{staticClass:"mb-7"},[_c('v-card-title',{staticStyle:{"padding-bottom":"0px"}},[_vm._v(" Cursos de "+_vm._s(_vm.$props.userData.companyData.name)+" ")]),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-select',{attrs:{"label":"Año del Grupo","items":_vm.yearList,"hide-details":""},on:{"change":_vm.onSelectedYear},model:{value:(_vm.selectedYear),callback:function ($$v) {_vm.selectedYear=$$v},expression:"selectedYear"}})],1),_c('v-data-table',{attrs:{"headers":_vm.tableCoursesHeaders,"items":_vm.groupListFiltered,"options":{ itemsPerPage: 5 },"footer-props":{
        'items-per-page-text': 'Cursos por página: ',
        'items-per-page-options': [5, 15, 30, 50, 100],
      }},scopedSlots:_vm._u([(_vm.groupListFiltered.length > 0)?{key:"body",fn:function({ items }){return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.name,class:{ active: _vm.selectedGroup == item },attrs:{"active":_vm.selectedGroup == item},on:{"click":function($event){return _vm.selectGroup(item)}}},[_c('td',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-no-wrap"},[_c('p',{staticClass:"font-weight-medium text--primary mb-n1"},[_vm._v(" "+_vm._s(item.courseName)+" ")]),_c('span',{staticClass:"text-xs text--disabled"},[_vm._v(_vm._s(item.name))])])])]),_c('td',[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"text-no-wrap"},[_c('p',{staticClass:"font-weight-medium text--primary mb-n1"},[_vm._v(" "+_vm._s(_vm.$store.state['app-group'].modalityOptions.filter(el => el.value == item.courseModality)[0].title)+" ")])])])]),_c('td',[_c('span',[_vm._v(_vm._s(item.students.length))])]),_c('td',[_c('span',[_vm._v(_vm._s(item.subjects.length))])]),_c('td',[_c('span',[_vm._v(_vm._s(item.workLoadTotal))])])])}),0)]}}:null,{key:"footer.page-text",fn:function({ pageStart, pageStop, itemsLength }){return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" de "+_vm._s(itemsLength)+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" No hay grupos para mostrar ")]},proxy:true}],null,true)})],1),_c('v-expand-transition',[(_vm.selectedGroup.name)?_c('v-card',{staticClass:"mb-7"},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.selectedGroup.name)+" - "+_vm._s(_vm.selectedGroup.courseName)+" "),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.tableStudentsHeaders,"items":_vm.selectedGroup.students,"options":{ itemsPerPage: 5 },"items-per-page":5,"footer-props":{
          'items-per-page-text': 'Alumnos por página: ',
          'items-per-page-options': [5, 15, 30, 50, 100],
        },"item-class":item => (_vm.selectedStudent == item ? 'active' : '')},on:{"click:row":_vm.selectStudent},scopedSlots:_vm._u([{key:"item.student",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.studentData.name)+" "+_vm._s(item.studentData.lastname))])]}},{key:"item.assists",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.attendancePercentage)+"%")]),_c('v-progress-linear',{staticClass:"project-progress mt-1",attrs:{"height":"6","rounded":"","color":_vm.resolveUserProgressVariant(item.attendancePercentage),"value":item.attendancePercentage}})]}},{key:"item.average",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.qualyTotal))])]}},{key:"item.actions",fn:function({ item }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"me-2",on:{"click":function($event){$event.stopPropagation();return _vm.showDetails(item)}}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.icons.mdiEye))])]}}],null,true)},[_c('span',[_vm._v("Ver detalle")])])]}},{key:"footer.page-text",fn:function({ pageStart, pageStop, itemsLength }){return [_vm._v(" "+_vm._s(pageStart)+"-"+_vm._s(pageStop)+" de "+_vm._s(itemsLength)+" ")]}},{key:"no-data",fn:function(){return [_vm._v(" No hay alumnos para mostrar ")]},proxy:true}],null,false,1610003180)})],1):_vm._e()],1),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.dialogs.details),callback:function ($$v) {_vm.$set(_vm.dialogs, "details", $$v)},expression:"dialogs.details"}},[_c('view-subjects',{attrs:{"selectedGroup":_vm.studentGroup,"studentData":_vm.selectedStudent.studentData,"title":_vm.selectedStudent.studentData
          ? `${_vm.selectedStudent.studentData.name} ${_vm.selectedStudent.studentData.lastname}`
          : ''}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }