<template>
  <div class="user-tab-overview">
    <!-- student courses -->
    <v-card class="mb-7">
      <v-card-title style="padding-bottom: 0px"> Cursos de {{ $props.userData.companyData.name }} </v-card-title>
      <v-col cols="12" sm="3" md="3">
        <v-select label="Año del Grupo" v-model="selectedYear" :items="yearList" hide-details @change="onSelectedYear">
        </v-select>
      </v-col>
      <v-data-table
        :headers="tableCoursesHeaders"
        :items="groupListFiltered"
        :options="{ itemsPerPage: 5 }"
        :footer-props="{
          'items-per-page-text': 'Cursos por página: ',
          'items-per-page-options': [5, 15, 30, 50, 100],
        }"
      >
        <template v-slot:body="{ items }" v-if="groupListFiltered.length > 0">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.name"
              :active="selectedGroup == item"
              :class="{ active: selectedGroup == item }"
              @click="selectGroup(item)"
            >
              <td>
                <div class="d-flex">
                  <div class="text-no-wrap">
                    <p class="font-weight-medium text--primary mb-n1">
                      {{ item.courseName }}
                    </p>
                    <span class="text-xs text--disabled">{{ item.name }}</span>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex align-center">
                  <div class="text-no-wrap">
                    <p class="font-weight-medium text--primary mb-n1">
                      {{
                        $store.state['app-group'].modalityOptions.filter(el => el.value == item.courseModality)[0].title
                      }}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <span>{{ item.students.length }}</span>
              </td>
              <td>
                <span>{{ item.subjects.length }}</span>
              </td>
              <td>
                <span>{{ item.workLoadTotal }}</span>
              </td>
            </tr>
          </tbody>
        </template>
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
        </template>
        <template v-slot:no-data> No hay grupos para mostrar </template>
      </v-data-table>
    </v-card>

    <!-- activity timeline -->
    <v-expand-transition>
      <v-card class="mb-7" v-if="selectedGroup.name">
        <v-card-title>
          {{ selectedGroup.name }} - {{ selectedGroup.courseName }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-data-table
          :headers="tableStudentsHeaders"
          :items="selectedGroup.students"
          :options="{ itemsPerPage: 5 }"
          :items-per-page="5"
          :footer-props="{
            'items-per-page-text': 'Alumnos por página: ',
            'items-per-page-options': [5, 15, 30, 50, 100],
          }"
          :item-class="item => (selectedStudent == item ? 'active' : '')"
          @click:row="selectStudent"
        >
          <template v-slot:item.student="{ item }">
            <span>{{ item.studentData.name }} {{ item.studentData.lastname }}</span>
          </template>
          <template v-slot:item.assists="{ item }">
            <span>{{ item.attendancePercentage }}%</span>
            <v-progress-linear
              height="6"
              rounded
              class="project-progress mt-1"
              :color="resolveUserProgressVariant(item.attendancePercentage)"
              :value="item.attendancePercentage"
            ></v-progress-linear>
          </template>
          <template v-slot:item.average="{ item }">
            <span>{{ item.qualyTotal }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="me-2" v-bind="attrs" v-on="on" @click.stop="showDetails(item)">{{
                  icons.mdiEye
                }}</v-icon>
              </template>
              <span>Ver detalle</span>
            </v-tooltip>
          </template>
          <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
            {{ pageStart }}-{{ pageStop }} de {{ itemsLength }}
          </template>
          <template v-slot:no-data> No hay alumnos para mostrar </template>
        </v-data-table>
      </v-card>
    </v-expand-transition>

    <v-dialog v-model="dialogs.details" width="800">
      <view-subjects
        :selectedGroup="studentGroup"
        :studentData="selectedStudent.studentData"
        :title="
          selectedStudent.studentData
            ? `${selectedStudent.studentData.name} ${selectedStudent.studentData.lastname}`
            : ''
        "
      ></view-subjects>
    </v-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { mdiDotsVertical, mdiEye, mdiFilePdf } from '@mdi/js'
import { onUnmounted, ref, watch, inject } from 'vue'
import groupStoreModule from '../../../group/groupStoreModule'
import ViewSubjects from '../student-tab-overview/ViewSubjects'

export default {
  components: {
    ViewSubjects,
  },
  props: {
    groupsData: {
      type: Array,
    },
    userData: {
      type: Object,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const appGroup = 'app-group'

    // Register module
    if (!store.hasModule(appGroup)) store.registerModule(appGroup, groupStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(appGroup)) store.unregisterModule(appGroup)
    })

    const selectedGroup = ref({})
    const selectedStudent = ref({})
    const studentGroup = ref({})
    const dialogs = ref({
      loading: false,
      details: false,
    })

    //Year
    const yearList = ref([])
    const selectedYear = ref()
    const groupListFiltered = ref([])
    const date = inject('date')
    const onSelectedYear = () => {
      groupListFiltered.value = props.groupsData.filter(g => date(g.createdAt).format('YYYY') == selectedYear.value)
    }

    const tableCoursesHeaders = [
      {
        text: 'Curso',
        value: 'courseName',
        sortable: false,
      },
      { text: 'Modalidad', value: 'courseModality', sortable: false },
      { text: 'Alumnos', value: 'students', sortable: false },
      { text: 'Materias', value: 'subjects', sortable: false },
      { text: 'Carga Horaria', value: 'workLoadTotal', sortable: false },
    ]

    const tableStudentsHeaders = [
      {
        text: 'Alumno',
        value: 'student',
        sortable: false,
      },
      { text: 'Asistido', value: 'assists', sortable: false },
      { text: 'Promedio', value: 'average', sortable: false },
      { text: 'Acciones', value: 'actions', sortable: false },
    ]

    const selectGroup = group => {
      if (selectedGroup.value.name == group.name) {
        selectedGroup.value = {}
      } else {
        selectedGroup.value = group
      }
    }

    const selectStudent = student => {
      if (selectedStudent.value.name == student.name) {
        selectedStudent.value = {}
      } else {
        selectedStudent.value = student
      }
    }

    const showDetails = student => {
      let groupFiltered = JSON.parse(JSON.stringify(selectedGroup.value))
      groupFiltered.students = groupFiltered.students.filter(s => s.student == student.student)
      selectedStudent.value = student
      studentGroup.value = groupFiltered
      dialogs.value.details = true
    }

    yearList.value = [...new Set(props.groupsData.map(g => date(g.createdAt).format('YYYY')))]
    if (!selectedYear.value && yearList.value.includes(date().format('YYYY'))) {
      selectedYear.value = date().format('YYYY')
      onSelectedYear()
    }

    const resolveUserProgressVariant = progrss => {
      if (progrss <= 59) return 'error'
      if (progrss >= 60 && progrss <= 69) return 'warning'
      if (progrss >= 70 && progrss <= 100) return 'success'

      return 'secondary'
    }

    return {
      groupListFiltered,
      yearList,
      selectedYear,
      onSelectedYear,
      studentGroup,
      showDetails,
      resolveUserProgressVariant,
      selectGroup,
      selectedGroup,
      selectStudent,
      selectedStudent,
      tableCoursesHeaders,
      tableStudentsHeaders,
      dialogs,
      icons: {
        mdiDotsVertical,
        mdiFilePdf,
        mdiEye,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.project-progress {
  min-width: 4rem;
}

@media print {
  .v-application {
    background: none !important;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}
</style>
